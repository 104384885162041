import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Image
  } from '@chakra-ui/react';
  
import { useSession, signIn, signOut } from "next-auth/react"
import React, { useState } from "react";

const Login = () => {

  const [loginEmail, setLoginEmail] = useState("");

  const validateAccount = ()=>{
    const emailArray = loginEmail.split("@");
    if(emailArray[1] == "depeur.onmicrosoft.com"){
      signIn();
    }

    window.location.href = "/api/auth/signin?domain="+emailArray[1];
  }
  const onChangeText = (key, val) => {
    setLoginEmail(val);
  }
  
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Image src='/img/rekening.png' maxWidth={200} alt='Dan Abramov' />
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <Heading fontSize={'2xl'}>Login</Heading>
              {/*<FormControl id="email">
                <FormLabel>E-Mail Adresse</FormLabel>
                <Input type="email" />
              </FormControl>*/}
              <FormControl id="password">
                <FormLabel>E-Mail</FormLabel>
                <Input
                  type="email"  
                  onChange={evt => onChangeText(`title`, evt.target.value)}
                  value={loginEmail}
                />
              </FormControl>
              <Stack spacing={10}>
                {/*<Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox>Eingeloggt bleiben</Checkbox>
                  <Link color={'#2D545E'} href="forgot-password">Passwort vergessen?</Link>
            </Stack>*/}
                <Button
                  bg={'orange'}
                  color={'black'}
                  _hover={{
                    opacity: '0.8',
                  }} onClick={()=>validateAccount()}>
                  Einloggen
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }

export default Login
  